<template>
  <section class="mybet">
    <div class="page-header">
      <h3 class="page-title">
        {{$t('myBets')}}
      </h3>      
    </div>

    <div v-if="loading">
      <div class="pixel-loader"></div>
    </div>
    <div v-else-if="!loading && isEmpty" class="no-item">
      <i class="fas fa-calendar-day text-info"></i>
      <h2 class="mb-2 text-warning mt-4">{{$t('noDataAvailable')}}</h2>
    </div>

    <div v-else class="event-cards row">      
        <event-card v-for="bet in bets" :event="bet.event" :key="bet.id" :viewType="viewType" :role="GetCurrentRole()">

          <template v-slot:header-left>
            <h6 class="card-title mt-1 mb-1">{{$t('single')}}</h6>
          </template>>

          <template v-slot:header-right>
            <h6 :class="['small','text-uppercase','mb-0', 'status-' + getStatus(bet.status)]"><small class="mr-2 text-more-muted" v-b-tooltip :title="$t('bettedOn')" >{{ GetDate(bet.betDate) }}</small> {{ getStatus(bet.status, true) }}</h6>
          </template>>

          <template v-slot:date>
            <div class="mb-1">
            <small class="text-more-muted" v-b-tooltip :title="$t('eventDate')">{{ GetFormattedDatetime(bet.event.startDate) }}</small>
            </div>
          </template>>          

          <template v-slot:teams>
            <div class="text-muted text-small"><i :class="GetCategoryIcon(bet.event.eventCategoryName)" v-b-tooltip.html.top :title="bet.event.eventCategoryName" /> {{ GetEventCardTitle(bet.event) }}</div>
            <h6>{{bet.event.title}}</h6>
          </template>>

          <template v-slot:body>
            <div class="border-top mt-2 pt-2">

                <div class="text-warning" v-if="bet.status == 6">
                  {{$t('canceledGame')}}
                </div>

                <div class="d-flex justify-content-between">
                  <div>{{ bet.gameTitle }} - {{ bet.gameOptionTitle }}</div>
                  <div><odds-value :odds="bet.odds" /></div>
                </div>
                
                <div class="d-flex justify-content-between">
                  <div>{{$t('stake')}}</div>
                  <div>{{ bet.amount }} {{CurrentCurrency}}</div>
                </div>

                <div class="d-flex justify-content-between text-info" v-if="bet.status == 0">
                  <span class="text-uppercase">{{$t('potentialWin')}}</span>
                  <span>{{ getPotentialWin(bet) }} {{CurrentCurrency}}</span>
                </div>

                <div class="d-flex justify-content-between text-success" v-if="bet.status == 1">
                  <span class="text-uppercase">{{$t('status.win')}}</span>
                  <span>{{ getPotentialWin(bet) }} {{CurrentCurrency}}</span>
                </div>
            </div>
          </template>
          <template v-slot:footer>
              <div class="text-center smaller text-more-muted p-1">Ticket ID: {{bet.id}}</div>
          </template>
        </event-card>
    </div>
  </section>
</template>

<script>
import eventCard from './share/event-card.vue'
import oddsValue from './share/odds-value';

export default {
  name: 'mybet',
  components: { 
    eventCard,
    oddsValue
  },
  data () {
    return {
      bets: [],
      viewType: 'mybet',
      loading: true,
      isEmpty: false
    }
  },
  created:function() {
    this.init();
    this.$root.$on('reload', () => { this.init(); });

    this.$bobwinHub.$on('myBetsReceived', response => {
      this.loading = false;
      this.bets = JSON.parse(response.data);
      this.isEmpty = this.bets.length == 0;
    });
  },
  methods: {
    init(){
      this.loading = true;
      this.getMyBets();
    },
    getMyBets() {
      this.CallHub({task: 'GetMyBets', callback: 'myBetsReceived', data: this.UserId});
    },
    getPotentialWin(bet) {
      let stake = bet.amount != null ? bet.amount : 100;
      let result = ((parseFloat(bet.odds) * 10) * (parseFloat(stake) * 10)) / 100;
      return result.toFixed(2);
    },
    getStatus(status, isText) {
      let result = isText ? this.$t('status.open') : 'OPEN';
      switch (status) {
        case 0:
          result = isText ? this.$t('status.open') : 'OPEN';
          break;
        case 1:
          result = isText ? this.$t('status.won') : 'WON';
          break;
        case 2:
          result = isText ? this.$t('status.lost') : 'LOST';
          break;
        case 3:
          result = isText ? this.$t('status.voting') : 'VOTING';
          break;
        case 4:
          result = isText ? this.$t('status.processing') : 'PROCESSING';
          break;          
        case 5:
          result = isText ? this.$t('status.pending') : 'PENDING';
          break;
        case 6:
          result = isText ? this.$t('status.canceled') : 'CANCELED';
          break;          
        default:
      }
      return result;
    }
  }
}
</script>
